function Process() {
  return (
    <div>
      <div className="process-section">
        <div className="container">
          <h1 className="accordion-heading text-center">The Process</h1>
          <p className="text-center accordion-text">
            Getting your site up and running has never been easier. We’ve
            streamlined the process into five straightforward sections to ensure
            a hassle-free experience from start to finish.
          </p>
          <div className="accordion" id="accordion-process">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h4>1. Discover</h4>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordion-process"
              >
                <div className="accordion-body">
                  <p>
                    We begin by getting to know your business inside and out.
                    <br />
                    <br />
                    Our team will learn about the services you provide, your
                    target audience, and your unique selling points. <br />
                    <br />
                    This in-depth understanding allows us to outline your
                    vision, set strategic goals, and design a layout that
                    perfectly aligns with your business objectives.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h4>2. Design</h4>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordion-process"
              >
                <div className="accordion-body">
                  <p>
                    We bring your vision to life with a collaborative design
                    process.
                    <br />
                    <br />
                    We will work closely with you to create a customised look
                    and feel for your site.
                    <br />
                    <br />
                    From selecting the perfect colour schemes to perfecting the
                    layout, we ensure that every aspect of your site reflects
                    your brand identity.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <h4>3. Development</h4>
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordion-process"
              >
                <div className="accordion-body">
                  <p>
                    Our skilled developers turn your design into a fully
                    functional website.
                    <br />
                    <br />
                    We ensure seamless performance and responsiveness across all
                    devices.
                    <br />
                    <br />
                    From coding to testing, we handle every technical detail to
                    bring your site to life.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <h4>4. Deployment</h4>
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordion-process"
              >
                <div className="accordion-body">
                  <p>
                    Once development is complete, we set your website live.
                    <br />
                    <br />
                    Our team manages the deployment process to ensure a smooth
                    deployment.
                    <br />
                    <br />
                    We handle all the technical aspects to ensure your site is
                    ready for your audience.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <h4>5. Support</h4>
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordion-process"
              >
                <div className="accordion-body">
                  <p>
                    After your site is live, we provide ongoing maintenance and
                    updates to keep it running smoothly.
                    <br />
                    <br />
                    From security updates to content changes, our team ensures
                    your site remains up-to-date and performs at its best.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
