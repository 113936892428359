function Approach() {
  const contactUsBtnHandle = () => {
    document.getElementById("contact-us").scrollIntoView();
  };

  return (
    <div>
      <div className="container home-content-one">
        <div className="text-center">
          <h1 className="home-content-one-heading">Our Approach</h1>
          <p className="home-content-one-body">
            At Peanut Bay Web, we help elevate your small business online,
            hassle-free, with our tailored website building and hosting
            services. From design to development, we handle everything with no
            upfront costs. You can focus on your core business while we craft a
            digital presence that sets you apart.
          </p>
          <button
            className="btn btn-outline-dark btn-lg home-content-one-price-btn"
            onClick={() => contactUsBtnHandle()}
          >
            Get In Touch
          </button>
        </div>
      </div>
    </div>
  );
}

export default Approach;
