import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import logo from "./images/logo-dark.webp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="App">
    <nav className="navbar navbar-dark gradient-background navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img className="logo-img" src={logo} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://reno.peanutbayweb.com/">
                Demo Site
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <App />
    <div className="footer">
      <div className="container text-center">
        <a className="footer-text mask" href="/">
          <strong>Home</strong>
        </a>
        <a className="footer-text mask" href="/about">
          <strong>About</strong>
        </a>
        <a className="footer-text mask" href="https://reno.peanutbayweb.com/">
          <strong>Demo Site</strong>
        </a>
        <a className="footer-text mask" href="/contact">
          <strong>Contact Us</strong>
        </a>
        <hr className="footer-divider" />
        <p className="footer-text mask">
          &#169; Copyright 2024 - Peanut Bay Web
        </p>
      </div>
    </div>
  </div>
);
