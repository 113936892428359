import ContactUs from "./ContactUs";
import JumbotronAni from "./home/JumbotronAni";
import Approach from "./home/Approach";
import ChooseUs from "./home/ChooseUs";
import Cost from "./home/Cost";
import Process from "./home/Process";

function Home() {
  return (
    <div>
      <JumbotronAni />
      <Approach />
      <ChooseUs />
      <Cost />
      <Process />
      <ContactUs backgroundColour="#1C1C1C" />
    </div>
  );
}

export default Home;
