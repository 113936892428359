import { useEffect, useRef, useState } from "react";

function JumbotronAni() {
  const textArr = ["Your Developer", "Your Partner", "Your Designer"];
  const [textIndex, setTextIndex] = useState(0);
  const [buildingText, setBuildingText] = useState(true);
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const dots = [];
    const dotCount = window.innerWidth > 650 ? 100 : 75; // Number of dots
    const maxDistance = 100; // Max distance for connecting dots
    const dotRadius = 2; // Fixed radius for the dots

    // Function to resize the canvas without distorting the dots
    const resizeCanvas = () => {
      const width = window.innerWidth;
      const height = window.innerWidth <= 500 ? window.innerHeight : window.innerHeight * 0.4; // 100vh on small screens, 40vh otherwise
      
      const scale = window.devicePixelRatio || 1; // Account for high-DPI screens
      
      // Set canvas dimensions and apply scaling
      canvas.width = width * scale;
      canvas.height = height * scale;
      ctx.scale(scale, scale);
    };

    // Dot Class
    class Dot {
      constructor(x, y, vx, vy) {
        this.x = x;
        this.y = y;
        this.vx = vx;
        this.vy = vy;
        this.radius = dotRadius;
      }

      // Draw the dot
      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        ctx.fillStyle = "#FF6500"; // Dot color
        ctx.fill();
        ctx.closePath();
      }

      // Update the dot's position
      update() {
        this.x += this.vx;
        this.y += this.vy;

        // Bounce off the edges
        if (
          this.x + this.radius > canvas.width / window.devicePixelRatio ||
          this.x - this.radius < 0
        ) {
          this.vx = -this.vx;
        }
        if (
          this.y + this.radius > canvas.height / window.devicePixelRatio ||
          this.y - this.radius < 0
        ) {
          this.vy = -this.vy;
        }
      }
    }

    // Create random dots
    for (let i = 0; i < dotCount; i++) {
      const x = (Math.random() * canvas.width) / window.devicePixelRatio;
      const y = (Math.random() * canvas.height) / window.devicePixelRatio;
      const vx = (Math.random() - 0.5) * 2;
      const vy = (Math.random() - 0.5) * 2;
      dots.push(new Dot(x, y, vx, vy));
    }

    // Calculate distance between dots and draw lines if they are close
    function connectDots() {
      for (let i = 0; i < dotCount; i++) {
        for (let j = i + 1; j < dotCount; j++) {
          const dist = Math.hypot(dots[i].x - dots[j].x, dots[i].y - dots[j].y);
          if (dist < maxDistance) {
            ctx.beginPath();
            ctx.moveTo(dots[i].x, dots[i].y);
            ctx.lineTo(dots[j].x, dots[j].y);
            ctx.strokeStyle = `rgba(255, 101, 0, ${1 - dist / maxDistance})`; // Fading line color
            ctx.stroke();
          }
        }
      }
    }

    // Animation loop
    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Update and draw each dot
      dots.forEach((dot) => {
        dot.update();
        dot.draw();
      });

      // Connect the dots with lines if they are close enough
      connectDots();

      requestAnimationFrame(animate);
    }

    // Initialize the canvas size and scale
    resizeCanvas();

    // Start animation
    animate();

    // Resize the canvas when the window is resized
    window.addEventListener("resize", resizeCanvas);

    // Cleanup on component unmount
    return () => {
      cancelAnimationFrame(animate);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  useEffect(() => {
    var text = textArr[textIndex];
    if (buildingText) {
      if (currentIndex < text.length) {
        const timeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, 150);

        return () => clearTimeout(timeout);
      } else {
        //Update the states to reverse the word
        const timeout = setTimeout(() => {
          setBuildingText(false);
          setCurrentIndex((prevIndex) => prevIndex - 1);
          setCurrentText((prevText) => prevText.slice(0, -1));
        }, 3000);
        return () => clearTimeout(timeout);
      }
    } else {
      if (currentIndex >= 0) {
        const timeout = setTimeout(() => {
          setCurrentIndex((prevIndex) => prevIndex - 1);
          setCurrentText((prevText) => prevText.slice(0, -1));
        }, 150);
        return () => clearTimeout(timeout);
      } else {
        //Update the states to build the next word
        const timeout = setTimeout(() => {
          if (textIndex === 2) {
            setTextIndex(0);
          } else {
            setTextIndex(textIndex + 1);
          }
          setBuildingText(true);
          setCurrentIndex(0);
        }, 1000);
        return () => clearTimeout(timeout);
      }
    }
  }, [currentIndex]);

  return (
    <div>
      <div className="dots-background-container">
        <canvas ref={canvasRef} className="dots-canvas"></canvas>
        <div className="landing-heading-mask fade-in-up">
          <h1 className="landing-heading">Peanut Bay Web</h1>
          <div className="typing-animation">
            <h1 className="landing-heading">&nbsp;{currentText}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JumbotronAni;
