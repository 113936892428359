import "./App.css";
import ContactUs from "./ContactUs";

function ContactUsPage() {
    return (
        <div>
            <div className="contact-us-bg"></div>
            <ContactUs backgroundColour="#1C1C1C" />
        </div>
    )
}

export default ContactUsPage;