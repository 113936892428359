import SmartPhoneIcon from "../images/icons/smartphone.png";
import WindowIcon from "../images/icons/web-design.png";
import MoneyIcon from "../images/icons/money-bag.png";

function ChooseUs() {
  return (
    <div>
      <div className="content-two">
        <div className="container content-two-body">
          <h1 className="text-center content-two-heading">Why Choose Us?</h1>
          <div className="row text-center">
            <div className="col-12 col-lg-4">
              <div className="content-two-card">
                <img
                  className="content-two-card-icon"
                  src={SmartPhoneIcon}
                  alt="Smart phone icon"
                />
                <h4 className="content-two-card-heading">Mobile Friendly</h4>
                <p className="content-two-card-body">
                  We design your site to be mobile-friendly first, then adapt it
                  for desktops for a seamless user experience.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="content-two-card">
                <img
                  className="content-two-card-icon"
                  src={WindowIcon}
                  alt="Window icon"
                />
                <h4 className="content-two-card-heading">Fully Responsive</h4>
                <p className="content-two-card-body">
                  Your website will be responsive across all screen sizes
                  ranging from mobiles to desktops.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="content-two-card">
                <img
                  className="content-two-card-icon"
                  src={MoneyIcon}
                  alt="Money icon"
                />
                <h4 className="content-two-card-heading">Cost Effective</h4>
                <p className="content-two-card-body">
                  Cost effective pricing to cater to the needs of small
                  businesses all without compromising quality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseUs;
