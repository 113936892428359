import WorkplaceImg from "../images/workplace.webp";
import CheckboxImg from "../images/icons/checkbox.webp";

function Cost() {
  return (
    <div className="content-three-bg">
      <div className="container content-three">
        <div className="row home-content-three-row">
          <div className="col-12 col-lg-6">
            <img
              src={WorkplaceImg}
              className="content-three-image"
              alt="Workplace desk image"
            />
          </div>
          <div className="col-12 col-lg-6 home-content-three-col">
            <h2 className="home-content-three-col-heading">
              $0 Upfront, $50 Per Month
              <br />
              12 Month Minimum Contract
            </h2>
            <p className="content-three-body">
              For just $50 a month and no down payment required, you’ll receive
              a standard 5-page website. If you require a more custom site, we
              offer custom solutions and pricing tailored to meet your specific
              needs.
            </p>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="home-content-three-col-body">
                  <img
                    className="content-three-col-checkbox-img"
                    src={CheckboxImg}
                  />
                  <p>
                    <strong>Unlimited Edits</strong>
                    <br />
                    Looking for a change? We'll do it for free.*
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="home-content-three-col-body">
                  <img
                    className="content-three-col-checkbox-img"
                    src={CheckboxImg}
                  />
                  <p>
                    <strong>No Hosting Fees</strong>
                    <br />
                    We will host your site for you at no extra charge.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="home-content-three-col-body">
                  <img
                    className="content-three-col-checkbox-img"
                    src={CheckboxImg}
                  />
                  <p>
                    <strong>Web Development</strong>
                    <br />
                    Professional hand built sites, line by line.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="home-content-three-col-body">
                  <img
                    className="content-three-col-checkbox-img"
                    src={CheckboxImg}
                  />
                  <p>
                    <strong>Free Email Support</strong>
                    <br />
                    Enjoy free email support for your site.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cost;
